import React from 'react';
import PropTypes from 'prop-types';

import Hotel from './Hotel';

const AddHotel = ({ hotelsService, workplaceService }) => (
  <Hotel isAdd hotelsService={ hotelsService } workplaceService={ workplaceService } />
);

AddHotel.propTypes = {
  hotelsService: PropTypes.object.isRequired,
  workplaceService: PropTypes.object.isRequired,
};

export default AddHotel;
