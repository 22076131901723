import renderComponents from './component';

const NAME = 'sw-hotels-page';
const DEFAULT_LABEL_SEARCHINPUT = 'Поиск отелей';

class HotelsModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const hotelsService = context.getService('Hotels');
    const appService = context.getService('App');
    const workplaceService = this.context.getService('Workplace');

    const { params: { hotelsPageId, hotelId } } = appService.get();

    if (hotelsPageId === 'searchHotels') {
      hotelsService.setSearchFilter(DEFAULT_LABEL_SEARCHINPUT, '');
    }

    this.page = renderComponents(this.box, {
      hotelsService,
      workplaceService,
      hotelsPageId,
      hotelId,
      moveToAddHotel: this.moveToAddHotel,
    });
  }

  moveToAddHotel = () => {
    this.context.navigate('/hotels/addHotel');
  }

  destroy() {
    this.page.unmount();
    const hotelsService = this.context.getService('Hotels');
    hotelsService.unSetSearchFilter();
  }
}

export default function (core) {
  core.addModule(NAME, HotelsModule);
}
