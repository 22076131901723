import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from '../styles/index.module.scss';

import { TEXT, FIELDS } from '../../../bi/constants/hotels';
import lodashReplaces from '../../../bi/utils/lodashReplaces';
import ImgInput from './ImgInput';

class ImagesBlock extends Component {
  static propTypes = {
    Images: PropTypes.array.isRequired,
    isNoEdit: PropTypes.bool,
    fieldsGroup: PropTypes.string.isRequired,
    setRef: PropTypes.func,
    onChangeField: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isNoEdit: false,
    setRef: () => {},
  }

  handleRemoveImg = id => () => {
    const { Images, fieldsGroup, onChangeField } = this.props;
    const updatedImages = Images.filter(({ key }) => key !== id);

    onChangeField(updatedImages, fieldsGroup);
  }

  handleAddImg = (e, inputValue) => {
    const { Images, fieldsGroup, onChangeField } = this.props;

    const key = lodashReplaces.uuid();
    const newImgByStatic = { OrigUrl: inputValue, Url: inputValue, key };
    const newImgByRoomGroups = { ThumbnailUrl: inputValue, key };
    const newImg = fieldsGroup === FIELDS.IMAGES ? newImgByStatic : newImgByRoomGroups;

    const updatedImages = [newImg, ...Images];

    onChangeField(updatedImages, fieldsGroup);
  }

  renderElement = ({ key, OrigUrl, ThumbnailUrl }) => (
    <li key={ key } className={ styles.item_img }>
      <img className={ styles.img } alt='img' src={ OrigUrl || ThumbnailUrl } />
      <div className={ styles.remove_button_img_wrap }>
        <button
          className={ `${styles.button} ${styles.button_close}` }
          onClick={ this.handleRemoveImg(key) }
          disabled={ this.props.isNoEdit }
        >
          <i className='material-icons'>clear</i>
        </button>
      </div>
    </li>
  )

  render() {
    const { Images, isNoEdit, fieldsGroup, setRef } = this.props;
    const titleHtml = fieldsGroup === FIELDS.IMAGES ? <h2>{TEXT.TITLE_IMAGES}</h2> : <h4>{TEXT.TITLE_IMAGES}</h4>;
    return (
      <div ref={ ref => setRef(ref) }>
        {titleHtml}
        <ImgInput
          onSubmit={ (e, value) => this.handleAddImg(e, value) }
          disabled={ isNoEdit }
        />
        <ul className={ styles.list }>
          {Images.map(img => this.renderElement(img))}
        </ul>
      </div>
    );
  }
}

export default ImagesBlock;
