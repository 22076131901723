import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Tooltip } from 'sw-ui';

import CircularLoaders from '../../../components/loaders';
import StickyPanel from '../../../components/Sticky';
import NoResult from '../../../components/NoResult';
import Button from '../../../components/button/Button';
import DecisionButtons from '../../../components/DecisionButtons';
import { GiataBlock } from '../components/GiataBlock';
import SimpleBlock from '../components/SimpleBlock';
import DescriptionBlock from '../components/Description';
import AmenitiesBlock from '../components/Amenities';
import ImagesBlock from '../components/ImagesBlock';
import { Travelline } from '../components/Travelline';
import { Contract } from '../components/Contract';
import { EcLcConditions } from '../components/EcLcConditions';
import Input from '../../../components/input';
import { HotelItem } from '../components/HotelItem';

import { makeDefaultForm } from '../../../bi/utils/hotels';

import { FIELDS, TEXT, ANCHORS, LOCALE } from '../../../bi/constants/hotels';
import { ENUMS } from '../../../bi/constants/employee';
import COMPONENTS from '../../../bi/constants/components';

import styles from '../styles/index.module.scss';
import stylesSearch from '../styles/search.module.scss';

const MODAL_TYPES = {
  ERROR: 'error',
  SAVE: 'save',
  DELETE: 'delete',
  RESTORE: 'restore',
  DUPLICATES: 'duplicates',
};

const LOAD_MODAL_WIDTH = '500px';

const LABELS = {
  RESTORE_HOTEL: 'Вы точно хотите восстановить отель?',
  TOOLTIP_TEXT: 'У отеля отсутствует поле "Имя отеля на английском"!\nЗаполните поле "Имя отеля на английском"!',
  NO_HOTEL: 'Что то пошло не так',
  SAVE_HOTEL: 'Отель успешно сохранен',
  SAVE_HOTEL_RESTORE: 'Отель успешно сохранен.\nПри обновлении отключенного отеля рейты ТЛ не обновляются!',
  LOAD_HOTE_DATA: 'Введите ID отеля A&A',
  FOUND_MATCHES: 'Найдены сопоставления',
  BEFORE_SAVE: 'Перед сохранением ознакомьтесь и решите какой отель оставить.',
};

class Hotel extends Component {
  static propTypes = {
    hotelsService: PropTypes.object.isRequired,
    workplaceService: PropTypes.object.isRequired,
    hotelId: PropTypes.string,
    isAdd: PropTypes.bool,
  };

  static defaultProps = {
    isAdd: false,
    hotelId: null,
  }

  constructor(props) {
    super(props);
    const { workplaceService, hotelsService } = props;

    const { enums } = workplaceService.get();

    this.state = {
      ...hotelsService.get(),
      enums,
      modalType: null,
      loadInputValue: '',
      duplicates: [],
      showDuplicates: false,
    };
    this.blockRefs = {};
  }

  componentDidMount() {
    const { hotelsService, workplaceService, isAdd } = this.props;
    const { formData } = this.state;

    const matchHotels = formData && formData.matching ? formData.matching : null;

    hotelsService.reset();
    this.loadTypesCohortAanda();
    this.loadEnums();
    this.loadRateTypes();

    if (isAdd) {
      this.loadDefaultHotel(matchHotels);
      this.loadNewPlans();
    } else {
      this.loadHotel();
      this.loadContract();
    }

    this.unsubscribeHotel = hotelsService.subscribe(this.updateHotel);
    this.unsubscribeWp = workplaceService.subscribe(this.updateWp);
  }

  componentWillUnmount() {
    const { hotelsService } = this.props;

    this.unsubscribeHotel();
    this.unsubscribeWp();
    hotelsService.reset();
  }

  loadDefaultHotel = async (matchHotels) => {
    const { hotelsService } = this.props;

    await hotelsService.changeField(makeDefaultForm(matchHotels), FIELDS.FORM_DATA);
    await hotelsService.changeField(false, FIELDS.IS_NO_EDIT);

    if (Object.keys(matchHotels).some(key => !!matchHotels[key].value)) {
      hotelsService.setMatchesChange();
    }

    this.setValidation();
  }

  loadHotel = async () => {
    const { hotelId, hotelsService } = this.props;

    await hotelsService.getHotel(hotelId);
    await this.loadRates();

    this.setValidation();
  }

  loadContract = async () => {
    const { hotelId, hotelsService } = this.props;
    await hotelsService.getContractHotel(hotelId);
  }

  setValidation = () => {
    const { formData } = this.state;
    const { hotelsService } = this.props;
    const { isValid, validation } = hotelsService.getValidation(formData);

    const paths = [
      { value: isValid, path: FIELDS.IS_VALID_FORM },
      { value: validation, path: FIELDS.VALIDATION },
    ];

    hotelsService.changeFields(paths);
  }

  loadEnums = async () => {
    await this.props.workplaceService.getEnums([ENUMS.CITIZENSHIP]);
  };

  loadRateTypes = async () => {
    await this.props.hotelsService.getRateTypes();
  };

  loadTypesCohortAanda = async () => {
    await this.props.hotelsService.getTypesCohortAanda();
  };

  loadNewPlans = async () => {
    await this.props.hotelsService.getNewPlans();
  };

  loadRates = async () => {
    await this.props.hotelsService.getRates();
  };

  updateHotel = state => this.setState({
    ...state,
  });

  updateWp = ({ enums }) => this.setState({ enums });

  setRef = id => (ref) => {
    this.blockRefs[id] = ref;
  }

  handleScroll = (id) => {
    const ref = this.blockRefs[id];
    if (!ref) {
      return;
    }
    window.scroll({ top: ref.offsetTop - 70, left: 0, behavior: 'smooth' });
  }

  handleShowModal = modalType => () => this.setState({ modalType });

  handleConfirm = () => {
    const { hotelsService: { normalizeHotel, updateHotel, addHotel }, isAdd } = this.props;
    const { formData } = this.state;
    const normalizedFormData = normalizeHotel(formData);
    const fnReq = isAdd ? addHotel : updateHotel;

    fnReq(normalizedFormData)
    .then(
      () => this.setState({ modalType: MODAL_TYPES.SAVE }),
      () => this.setState({ modalType: formData.Deleted ? MODAL_TYPES.SAVE : MODAL_TYPES.ERROR }),
    );
  };

  handleDeleteHotel = () => {
    const { hotelsService, hotelId } = this.props;
    hotelsService.deleteHotel(hotelId).then(() => {
      this.setState({ modalType: null });
      location.reload();
    });
  };

  handleRestoreHotel = async () => {
    const { hotelsService, hotelId } = this.props;

    await hotelsService.restoreHotel(hotelId).then(({ Duplicates }) => {
      this.setState({ modalType: null });

      if (Duplicates.length) {
        return this.setState({ showDuplicates: true, duplicates: Duplicates });
      }

      return location.reload();
    });
  };

  handleLoadHotelData = () => {
    const { hotelsService: { getHotelDataAA } } = this.props;
    const { loadInputValue } = this.state;

    getHotelDataAA(loadInputValue);
    this.handleShowModal(null);
  };

  handleChangeEdit = isNoEdit => () => {
    this.props.hotelsService.changeField(isNoEdit, FIELDS.IS_NO_EDIT);
  };

  handleLoadInput = (value) => {
    this.setState({ loadInputValue: value });
  };

  renderLoading = () => <CircularLoaders wrapClassName='content-loader-wrap' />;

  renderEmptyList = () => (
    <div className='empty-list'>
      {LABELS.NO_HOTEL}
    </div>
  );

  renderDeleteModal = () => (
    <Dialog onClick={ this.handleShowModal(null) }>
      <div className={ styles.dialog }>
        <div className={ styles.header_dialog }>Вы точно хотите удалить отель из дампа?</div>
        <div className={ `${styles.row} ${styles.right}` }>
          <DecisionButtons
            onCancel={ this.handleShowModal(null) }
            onSave={ this.handleDeleteHotel }
            labelSave={ TEXT.BUTTONS.YES }
          />
        </div>
      </div>
    </Dialog>
  );

  renderRestoreModal = () => {
    const { loadingRestoreHotel } = this.state;

    return (
      <Dialog onClick={ this.handleShowModal(null) }>
        <div className={ styles.dialog }>
          <div className={ styles.header_dialog }> {LABELS.RESTORE_HOTEL}</div>
          <div className={ `${styles.row} ${styles.right}` }>
            <DecisionButtons
              onCancel={ this.handleShowModal(null) }
              onSave={ this.handleRestoreHotel }
              labelSave={ TEXT.BUTTONS.YES }
              waitingResponse={ loadingRestoreHotel }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  renderSaveModal = () => {
    const {
      formData: {
        Deleted,
      },
    } = this.state;

    const text = Deleted ? LABELS.SAVE_HOTEL_RESTORE : LABELS.SAVE_HOTEL;

    const onClose = () => {
      this.handleShowModal(null);
      location.reload();
    };

    return (
      <NoResult
        message={ text }
        onClose={ onClose }
      />
    );
  };

  renderErrorModal = () => {
    const {
      formData: {
        Deleted,
      },
    } = this.state;

    const text = Deleted ? LABELS.NO_HOTEL : LABELS.NO_HOTEL;

    return (
      <NoResult
        message={ text }
        onClose={ this.handleShowModal(null) }
      />
    );
  };

  renderLoadModal = () => {
    const { loadInputValue, waitPreloader, autocompliteModal } = this.state;
    const { hotelsService: { setAutocompliteModal } } = this.props;

    if (!autocompliteModal) return null;

    return (
      <Dialog width={ LOAD_MODAL_WIDTH } onClick={ () => setAutocompliteModal(false) }>
        <div className={ styles.dialog }>
          <div className={ styles.header_dialog }> {LABELS.LOAD_HOTE_DATA}</div>
          <Input
            value={ loadInputValue }
            field={ FIELDS.ADD_HOTEL.HOTEL_ID }
            onChange={ ({ target: { value } }) => this.handleLoadInput(value) }
          />
          <div className={ `${styles.row} ${styles.right}` }>
            <DecisionButtons
              waitingResponse={ waitPreloader }
              onCancel={ () => setAutocompliteModal(false) }
              onSave={ this.handleLoadHotelData }
              labelSave={ TEXT.BUTTONS.SAVE }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  modalTypesFn = {
    [MODAL_TYPES.DELETE]: this.renderDeleteModal,
    [MODAL_TYPES.RESTORE]: this.renderRestoreModal,
    [MODAL_TYPES.ERROR]: this.renderErrorModal,
    [MODAL_TYPES.SAVE]: this.renderSaveModal,
  };

  renderDialog = (type) => {
    const renderFn = this.modalTypesFn[type];
    return renderFn ? renderFn() : null;
  };

  renderDeletedBtn = () => {
    const {
      formData: {
        Deleted,
      },
    } = this.state;
    const { isAdd } = this.props;

    if (isAdd || Deleted) return null;

    return (
      <Button
        label={ TEXT.BUTTONS.DELETE }
        className={ styles.btn_main_item }
        onClick={ this.handleShowModal(MODAL_TYPES.DELETE) }
      />
    );
  };

  renderEditBtn = () => {
    const { isNoEdit } = this.state;
    const { isAdd } = this.props;

    if (isAdd) return null;

    return (
      <Button
        label={ TEXT.BUTTONS.EDIT }
        className={ styles.btn_main_item }
        disabled={ !isNoEdit }
        onClick={ this.handleChangeEdit(false) }
      />
    );
  };

  renderLoadHotelDataBtn = () => {
    const { isAdd, hotelsService: { setAutocompliteModal } } = this.props;

    if (!isAdd) return null;

    return (
      <Button
        onClick={ () => setAutocompliteModal(true) }
        label={ TEXT.BUTTONS.LOAD }
        className={ styles.btn_main_item }
      />
    );
  };

  renderButtonTooltip = () => {
    const {
      formData: {
        simpleFields: {
          NameEn,
        },
      },
    } = this.state;

    if (NameEn) return null;

    return (
      <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.TOP } styles={ { 'white-space': 'pre-line' } }>
        {LABELS.TOOLTIP_TEXT}
      </Tooltip>
    );
  };

  renderDialogMatchesHotels = () => {
    const { hotelsService } = this.props;
    const { duplicates, showDuplicates, mappingGiata } = this.state;

    if (!showDuplicates || !duplicates.length) return null;

    const hotelsHtml = duplicates.map(item => (
      <HotelItem
        item={ item }
        hotelsService={ hotelsService }
        mappingGiata={ mappingGiata }
      />
    ));

    return (
      <Dialog onClick={ () => this.setState({ showDuplicates: false }) }>
        <div className={ styles.dialog_diplicates }>
          <ul className={ stylesSearch.list }>
            <h3>{ LABELS.FOUND_MATCHES }</h3>
            <span>{ LABELS.BEFORE_SAVE }</span>
            { hotelsHtml }
          </ul>
        </div>
      </Dialog>
    );
  };

  renderRestoreBtn = () => {
    const {
      isNoEdit,
      formData: {
        Deleted,
        simpleFields: {
          NameEn,
        },
      },
    } = this.state;
    const { isAdd } = this.props;

    if (isAdd && !Deleted) return null;

    return (
      <div className={ 'sw-tooltip-wrapper' }>
        <Button
          label={ TEXT.BUTTONS.RESTORE }
          className={ styles.btn_main_item }
          onClick={ this.handleShowModal(MODAL_TYPES.RESTORE) }
          disabled={ !NameEn || !isNoEdit }
        />
        { this.renderButtonTooltip() }
      </div>
    );
  };

  renderButtonsBlock = () => {
    const {
      isNoEdit,
      isValidForm,
      isRatesValid,
      isConditionsValid,
      comparesAllMatches,
      isChangeMatches,
      isHotelWithCompare,
    } = this.state;

    const disabled = isNoEdit
      || !isValidForm
      || !isRatesValid
      || !isConditionsValid
      || comparesAllMatches.Exists
      || (isChangeMatches && !isHotelWithCompare);

    return (
      <div className={ styles.btn_main_wrap }>
        <Button
          onClick={ this.handleConfirm }
          label={ TEXT.BUTTONS.SAVE }
          disabled={ disabled }
          className={ styles.btn_main_item }
        />
        { this.renderLoadHotelDataBtn() }
        { this.renderEditBtn() }
        { this.renderDeletedBtn() }
        { this.renderRestoreBtn() }
      </div>
    );
  }

  renderStickyPanel = () => (
    <div className={ styles.sticky }>
      <StickyPanel
        adjustment={ 70 }
      >
        <ul className={ styles.sticky_list }>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.SIMPLE_BLOCK) }>{ TEXT.TITLE_SIMPLE_BLOCK }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.EC_LC_CONDITIONS) }>{ TEXT.EC_LC_CONDITIONS.TITLE }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.REQUISITES) }>{ TEXT.TITLE_REQUISITES }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.MATCHING) }>{ TEXT.TITLE_MATCHING }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.DESCRIPTION) }>{ TEXT.TITLE_DESCRIPTION }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.DESCRIPTION_EN) }>{ TEXT.TITLE_DESCRIPTION_EN }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.AMENITIES) }>{ TEXT.TITLE_AMENITIES }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.AMENITIES_EN) }>{ TEXT.TITLE_AMENITIES_EN }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.IMAGES) }>{ TEXT.TITLE_IMAGES }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.TRAVELLINE) }>{ TEXT.TITLE_TRAVELLINE }</a>
          </li>
          <li className={ styles.sticky_item }>
            <a className={ styles.btn } onClick={ () => this.handleScroll(ANCHORS.CONTRACT) }>{ TEXT.HOTEL_CONTRACT.TITLE }</a>
          </li>
        </ul>
      </StickyPanel>
    </div>
  )

  render() {
    const { hotelsService, isAdd, hotelId } = this.props;
    const {
      modalType,
      formData,
      isNoEdit,
      hotelLoad,
      enums,
      validation,
      btnRequest,
      regionSuggest,
      rateTypes,
      Rates,
      Plans,
      bar,
      connected,
      hideRates,
      WorksWithVat,
      PriceInclVat,
      discount,
      ratesValidation,
      activatedData,
      contract,
      conditionsValidation,
      typesCohortAanda,
      isChangeMatches,
      isHotelWithCompare,
      comparesAllMatches,
      showComparesDialog,
      mappingGiata,
    } = this.state;

    const isEditHotelLoad = !isAdd && hotelLoad;

    if (isEditHotelLoad || btnRequest) {
      return this.renderLoading();
    }

    if (!formData && !isEditHotelLoad) {
      return this.renderEmptyList();
    }

    const {
      Deleted,
      Source,
      UpdateDate,
      IdSmartway,
      simpleFields,
      Requisites,
      additions,
      restrictions,
      matching,
      Description,
      DescriptionEn,
      Amenities,
      AmenitiesEn,
      Images,
      ecLcConditions,
    } = formData;

    const deleteMsgHtml = Deleted && <div className={ styles.deleted }>Отель удален</div>;

    const unChangeStaticFields = [
      { value: hotelId, label: 'Id' },
      { value: Source, label: 'Источник' },
      { value: UpdateDate, label: 'Дата обновления' },
    ];

    const unChangeMatchingFields = [{ value: IdSmartway, label: 'IdSmartway' }];

    const simpleFieldsWithoutShortDescription = Object.keys(simpleFields)
      .filter(key => key !== FIELDS.SHORT_DESCRIPTION && key !== FIELDS.SHORT_DESCRIPTION_EN)
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = simpleFields[key];

        return obj;
      }, {});

    const simpleFieldsShortDescription = Object.keys(simpleFields)
      .filter(key => key === FIELDS.SHORT_DESCRIPTION || key === FIELDS.SHORT_DESCRIPTION_EN)
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = simpleFields[key];

        return obj;
      }, {});

    const guaranteedFields = {
      TypeCohortAanda: additions.TypeCohortAanda,
      PriceGuaranteeAanda: additions.PriceGuaranteeAanda,
      AandaGuaranteeDisabled: restrictions.AandaGuaranteeDisabled,
    };

    const simpleAndAdditionsFields = {
      ...simpleFieldsWithoutShortDescription,
      ...guaranteedFields,
      IsSmartHotel: additions.IsSmartHotel,
      ...simpleFieldsShortDescription,
    };

    return (
      <div className={ styles.wrap }>
        <div className={ styles.content }>
          {deleteMsgHtml}
          <SimpleBlock
            title={ TEXT.TITLE_SIMPLE_BLOCK }
            hotelsService={ hotelsService }
            simpleFields={ simpleAndAdditionsFields }
            isNoEdit={ isNoEdit }
            fieldsGroup={ FIELDS.SIMPLE_FIELDS }
            enums={ enums }
            typesCohortAanda={ typesCohortAanda }
            unChangeFields={ unChangeStaticFields }
            regionSuggest={ regionSuggest }
            validation={ validation }
            setRef={ this.setRef(ANCHORS.SIMPLE_BLOCK) }
          />
          <EcLcConditions
            isNoEdit={ isNoEdit }
            conditions={ ecLcConditions }
            validation={ conditionsValidation }
            setRef={ this.setRef(ANCHORS.EC_LC_CONDITIONS) }
            updateConditions={ hotelsService.updateEcLcConditionsField }
            addConditions={ hotelsService.addEcLcConditions }
            deleteConditions={ hotelsService.deleteEcLcConditions }
          />
          <SimpleBlock
            title={ TEXT.TITLE_REQUISITES }
            hotelsService={ hotelsService }
            simpleFields={ Requisites }
            isNoEdit={ isNoEdit }
            fieldsGroup={ FIELDS.REQUISITES }
            typesCohortAanda={ typesCohortAanda }
            validation={ validation }
            setRef={ this.setRef(ANCHORS.REQUISITES) }
          />
          <SimpleBlock
            title={ TEXT.TITLE_MATCHING }
            hotelsService={ hotelsService }
            simpleFields={ matching }
            isNoEdit={ isNoEdit }
            fieldsGroup={ FIELDS.MATCHING }
            typesCohortAanda={ typesCohortAanda }
            unChangeFields={ unChangeMatchingFields }
            setRef={ this.setRef(ANCHORS.MATCHING) }
          />
          <GiataBlock
            hotelsService={ hotelsService }
            comparesAllMatches={ comparesAllMatches }
            isChangeMatches={ isChangeMatches }
            isHotelWithCompare={ isHotelWithCompare }
            idGiata={ matching.IdGiata.value }
            countryCode={ simpleFields.CountryCode }
            showComparesDialog={ showComparesDialog }
            mappingGiata={ mappingGiata }
          />
          <DescriptionBlock
            hotelsService={ hotelsService }
            Description={ Description }
            isNoEdit={ isNoEdit }
            setRef={ this.setRef(ANCHORS.DESCRIPTION) }
            title={ TEXT.TITLE_DESCRIPTION }
            field={ FIELDS.DESCRIPTION }
          />
          <DescriptionBlock
            hotelsService={ hotelsService }
            Description={ DescriptionEn }
            isNoEdit={ isNoEdit }
            setRef={ this.setRef(ANCHORS.DESCRIPTION_EN) }
            title={ TEXT.TITLE_DESCRIPTION_EN }
            field={ FIELDS.DESCRIPTION_EN }
            locale={ LOCALE.EN }
          />
          <AmenitiesBlock
            hotelsService={ hotelsService }
            Amenities={ Amenities }
            isNoEdit={ isNoEdit }
            setRef={ this.setRef(ANCHORS.AMENITIES) }
            title={ TEXT.TITLE_AMENITIES }
            field={ FIELDS.AMENITIES }
          />
          <AmenitiesBlock
            hotelsService={ hotelsService }
            Amenities={ AmenitiesEn }
            isNoEdit={ isNoEdit }
            setRef={ this.setRef(ANCHORS.AMENITIES_EN) }
            title={ TEXT.TITLE_AMENITIES_EN }
            field={ FIELDS.AMENITIES_EN }
          />
          <ImagesBlock
            Images={ Images }
            isNoEdit={ isNoEdit }
            fieldsGroup={ FIELDS.IMAGES }
            setRef={ this.setRef(ANCHORS.IMAGES) }
            onChangeField={ hotelsService.changeField }
          />
          <Travelline
            rates={ Rates }
            plans={ Plans }
            connected={ connected }
            hideRates={ hideRates }
            worksWithVat={ WorksWithVat }
            priceInclVat={ PriceInclVat }
            discount={ discount }
            activatedData={ activatedData }
            bar={ bar }
            types={ rateTypes }
            validation={ ratesValidation }
            isNoEdit={ isNoEdit }
            isAdd={ isAdd }
            setRef={ this.setRef(ANCHORS.TRAVELLINE) }
            onAddRate={ hotelsService.addRate }
            onAddPlan={ hotelsService.addPlan }
            onDeleteRate={ hotelsService.deleteRate }
            onChangeField={ hotelsService.updateFieldsRate }
            onDeletePlan={ hotelsService.deletePlan }
            onChangeVat={ hotelsService.updateVat }
            onChangeBar={ hotelsService.updateBar }
            onChangeDiscount={ hotelsService.updateDiscount }
            onChangeConnected={ hotelsService.updateConnectionTL }
            onChangeHideRates={ hotelsService.updateHideRatesTL }
          />
          <Contract
            setRef={ this.setRef(ANCHORS.CONTRACT) }
            contract={ contract }
            isNoEdit={ isNoEdit }
            onAddContract={ hotelsService.addContract }
            onDeleteContract={ hotelsService.deleteContract }
            onChangeContractField={ hotelsService.updateContractField }
          />
        </div>
        { this.renderStickyPanel() }
        { this.renderButtonsBlock() }
        { this.renderDialog(modalType) }
        { this.renderLoadModal() }
        { this.renderDialogMatchesHotels() }
      </div>
    );
  }
}

export default Hotel;
