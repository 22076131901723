import React, { Component } from 'react';
import { Checkbox } from 'sw-ui';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';
import Autocomplete from 'react-autocomplete';

import Input from '../../../components/input';
import Suggest from '../../../components/Suggest';
import TextArea from '../../../components/textarea';

import styles from '../styles/index.module.scss';

import { FIELDS, HOTEL_TYPES, LOCALE, PROVIDERS_VALUE } from '../../../bi/constants/hotels';
import ImgInput from './ImgInput';
import Button from '../../../components/button/Button';

import cn from '../../../utils/classNames';
import lodashReplaces from '../../../bi/utils/lodashReplaces';
import { zeroToFife, float } from '../../../bi/constants/regExp';
import { TIME_SECONDS } from '../../../constants/time';

import { getLabelTypesCohortAanda, getNormalimzeTime } from '../../../bi/utils/hotels';

const MENU_STYLE = {
  position: 'absolute',
  left: 0,
  top: 51,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 6,
};

const RELATED_FIELDS = {
  [FIELDS.COUNTRY]: FIELDS.COUNTRY_CODE,
  [FIELDS.REGION_NAME]: [FIELDS.REGION_ID, FIELDS.CITY, FIELDS.CITY_EN],
};

const isCantInput = (field, value) => {
  const isInputInvalid = (val, reg) => !reg.test(val);
  switch (field) {
    case FIELDS.STARS: {
      return isInputInvalid(value, zeroToFife);
    }

    case FIELDS.RATING:
    case FIELDS.DISTANCE_FROM_CENTER:
    case FIELDS.LATITUDE:
    case FIELDS.LONGITUDE: {
      return isInputInvalid(value, float);
    }
  }

  return false;
};

const isRelatedFields = field =>
  Object.values(RELATED_FIELDS)
  .flat()
  .includes(field);

const LABELS = {
  ENGLISH: 'на английском',
};

class SimpleBlock extends Component {
  static propTypes = {
    hotelsService: PropTypes.object.isRequired,
    simpleFields: PropTypes.object.isRequired,
    isNoEdit: PropTypes.bool,
    fieldsGroup: PropTypes.string.isRequired,
    typesCohortAanda: PropTypes.array.isRequired,
    title: PropTypes.string,
    setRef: PropTypes.func,
    enums: PropTypes.object,
    validation: PropTypes.object,
    unChangeFields: PropTypes.array,
    regionSuggest: PropTypes.array,
  };

  static defaultProps = {
    isNoEdit: false,
    enums: null,
    validation: null,
    title: 'Просто заголовок',
    setRef: () => {},
    unChangeFields: [],
    regionSuggest: [],
  }

  state = { isShowInputThumbnail: false }

  handleChangeFields = async (value, field) => {
    const { fieldsGroup, hotelsService: { changeField, doValidation } } = this.props;

    let path;

    if (field === FIELDS.PRICE_GUARANTEE || field === FIELDS.SMART_HOTEL) {
      path = `formData.additions.${field}.${FIELDS.VALUE}`;
    } else if (field === FIELDS.AANDA_GUARANTEE) {
      path = `formData.restrictions.${field}.${FIELDS.VALUE}`;
    } else {
      path = `${fieldsGroup}.${field}.${FIELDS.VALUE}`;
    }

    await changeField(value, path);

    doValidation(value, field);
  };

  handleChangeInput = async (e, field) => {
    const { target: { value } } = e;
    if (isCantInput(field, value)) {
      e.preventDefault();
      return;
    }

    const { fieldsGroup, hotelsService: { doValidation, changeField } } = this.props;
    await changeField(value, `${fieldsGroup}.${field}.${FIELDS.VALUE}`);

    doValidation(value, field);
  };

  handleShowThumbnail = isShowInputThumbnail => () => this.setState({ isShowInputThumbnail });

  handleSelect = async (item, field) => {
    const { fieldsGroup, hotelsService: { doValidation, changeField } } = this.props;

    if (field === FIELDS.TYPE_COHORT_AANDA) {
      await changeField(item.id === 0 ? 'undefined' : item.id, `formData.additions.${field}.${FIELDS.VALUE}`);
    } else {
      await changeField(item.name, `${fieldsGroup}.${field}.${FIELDS.VALUE}`);
    }

    const relatedField = RELATED_FIELDS[field];

    if (relatedField) {
      await changeField(item.Code, `${fieldsGroup}.${relatedField}.${FIELDS.VALUE}`);
    }

    doValidation(item.Name, field);
  }

  handleSelectAutocomplete = async ({ City, CityEn, RegionId }, field) => {
    const { fieldsGroup, hotelsService: { doValidation, changeField } } = this.props;

    await changeField(City, `${fieldsGroup}.${field}.${FIELDS.VALUE}`);

    await changeField(City, `${fieldsGroup}.${FIELDS.CITY}.${FIELDS.VALUE}`);
    await changeField(CityEn, `${fieldsGroup}.${FIELDS.CITY_EN}.${FIELDS.VALUE}`);
    await changeField(RegionId, `${fieldsGroup}.${FIELDS.REGION_ID}.${FIELDS.VALUE}`);

    doValidation(City, field);
  }

  handleChangeAutocomplete = async (value, field) => {
    const { hotelsService: { autocompleteRegion, changeField, doValidation }, fieldsGroup } = this.props;
    await changeField(value, `${fieldsGroup}.${field}.${FIELDS.VALUE}`);

    const suggest = await autocompleteRegion(value);
    changeField(suggest, FIELDS.REGION_SUGGEST);

    doValidation(value, field);
  }

  renderEnglishAttention = (label, locale) => (
    locale === LOCALE.EN && <div className={ styles.english }>{ label } { LABELS.ENGLISH }</div>
  );

  renderInput = (label, path, valueData) => {
    const { validation, isNoEdit } = this.props;
    const valid = validation ? lodashReplaces.getValueInObjByPath(validation, path) : '';
    const disabled = isNoEdit || path === PROVIDERS_VALUE.ID_GIATA;

    return (
      <div>
        <Input
          value={ valueData }
          field={ path }
          label={ label }
          onChange={ isRelatedFields(path) ? null : (e, field) => this.handleChangeInput(e, field) }
          disabled={ disabled }
          valid={ valid }
        />
      </div>
    );
  }

  renderTextArea = (label, path, valueData, locale) => {
    const { validation, isNoEdit } = this.props;
    const valid = validation ? lodashReplaces.getValueInObjByPath(validation, path) : '';

    return (
      <div>
        <TextArea
          value={ valueData }
          placeholder={ 'Опишите отель, кратко.' }
          className={ styles.simple_text_area }
          onChange={ value => this.handleChangeFields(value, path) }
          disabled={ isNoEdit }
          valid={ valid }
          label={ label }
          labelTop
        />
        { this.renderEnglishAttention(label, locale) }
      </div>
    );
  }

  rendenCheckbox = (label, path, valueData) => {
    const { simpleFields } = this.props;

    if (path === FIELDS.AANDA_GUARANTEE && !simpleFields.PriceGuaranteeAanda.value) return null;

    return (
      <Checkbox
        value={ valueData }
        field={ path }
        onChange={ value => this.handleChangeFields(value, path) }
        disabled={ this.props.isNoEdit || path === FIELDS.PRICE_GUARANTEE }
      >
        {label}
      </Checkbox>
    );
  }

  renderImg = (label, path, valueData) => {
    const { isShowInputThumbnail } = this.state;
    const { validation } = this.props;
    const valid = validation ? lodashReplaces.getValueInObjByPath(validation, path) : '';
    const imgInputHtml = isShowInputThumbnail && (
      <div className={ styles.thumbnail_input_wrap } >
        <ImgInput
          onSubmit={ (e, value) => this.handleChangeFields(value, path) }
          onClose={ this.handleShowThumbnail(false) }
        />
      </div>);
    const imgHtml = !valid ? (
      <div className={ styles.thumbnail_img }>
        <img className={ styles.background_picture } src={ valueData } alt='Thumbnail' />
        <img className={ styles.picture } src={ valueData } alt='Thumbnail' />
      </div>
    ) : (
      <div className={ styles.default_img }>Загрузите Thumbnail в приблизительном соотношиние ширины к высоте: 8/5.<br />Пример: ширина - 320, высота - 220</div>
      );

    const THUMBNAIL_TEXT = valid ? 'Загрузить Thumbnail' : 'Изменить Thumbnail';

    return (
      <div className={ styles.thumbnail_wrap }>
        <div className={ styles.img_wrap }>
          <label>{label}</label>
          {imgHtml}
          <div className={ styles['error-msg'] }>{valid}</div>
        </div>
        <div className={ styles.btn_wrap }>
          <Button
            disabled={ this.props.isNoEdit }
            label={ isShowInputThumbnail ? 'Отменить' : THUMBNAIL_TEXT }
            onClick={ this.handleShowThumbnail(!isShowInputThumbnail) }
          />
          {imgInputHtml}
        </div>
      </div>
    );
  }

  getSelectList = (field, value = '') => {
    const { enums, typesCohortAanda } = this.props;

    if (enums && field === FIELDS.COUNTRY) {
      return enums.citizenship.map((el, i) => ({ ...el, name: el.Name, id: i }));
    }

    if (field === FIELDS.TYPE) {
      return HOTEL_TYPES;
    }

    if (field === FIELDS.TYPE_COHORT_AANDA) {
      if (typesCohortAanda.includes(({ id }) => id !== value)) {
        return [...typesCohortAanda, { name: value, id: value }];
      }
      return typesCohortAanda;
    }

    return [];
  }

  renderSelect = (label, path, valueData) => {
    const { typesCohortAanda } = this.props;
    const currentLabel = path === FIELDS.TYPE_COHORT_AANDA
      ? getLabelTypesCohortAanda(typesCohortAanda, valueData)
      : valueData;
    const { isNoEdit, validation } = this.props;
    const valid = validation ? lodashReplaces.getValueInObjByPath(validation, path) : '';
    const list = this.getSelectList(path, valueData);

    if (list.length === 0) {
      return 'подожди подожди';
    }
    return (
      <Suggest
        title={ label }
        valid={ valid }
        suggests={ list }
        currentLabel={ currentLabel || '' }
        onSelect={ value => this.handleSelect(value, path) }
        withScroll
        disabled={ isNoEdit }
      />
    );
  };

  renderAutocomolete = (label, field, valueData) => {
    const { validation, regionSuggest, isNoEdit } = this.props;
    const valid = lodashReplaces.getValueInObjByPath(validation, field);

    const messageHtml = valid && <span className={ styles['error-msg'] }>{ valid }</span>;
    const wrapperStyle = { style: styles.wrap_auto_input };
    const inputProps = {
      className: `${styles.input} ${valid ? styles.not_valid_input : ''}`,
      disabled: isNoEdit,
      type: 'search',
    };

    const renderItem = (item) => {
      if (!item) {
        return null;
      }

      return (
        <div className={ styles['autocomplete-item'] }>
          {`${item.City}`}
        </div>
      );
    };

    const checkSuggest = regionSuggest[0] ? regionSuggest : [];

    return (
      <div className={ `${styles['auto-input']}` }>
        <label>{label}</label>
        <Autocomplete
          menuStyle={ MENU_STYLE }
          value={ valueData }
          items={ checkSuggest }
          getItemValue={ item => item.City }
          onSelect={ (value, item) => this.handleSelectAutocomplete(item, field) }
          onChange={ ({ target: { value } }) => this.handleChangeAutocomplete(value, field) }
          inputProps={ inputProps }
          wrapperStyle={ wrapperStyle }
          renderItem={ renderItem }
        />
        { messageHtml }
      </div>
    );
  }
  renderClock = (label, path, valueData) => {
    const { validation, isNoEdit } = this.props;

    const valid = validation ? lodashReplaces.getValueInObjByPath(validation, path) : '';
    const normalizeValueData = getNormalimzeTime(valueData);

    const messageHtml = valid &&
      <span className={ styles['error-msg'] }>{ valid }</span>;

    return (
      <div className={ styles.date_wrap }>
        <label className={ styles.date_label }>{label}</label>
        <DatePicker
          dateFormat={ false }
          timeFormat={ TIME_SECONDS }
          locale='ru'
          value={ normalizeValueData }
          onChange={ value => this.handleChangeFields(value, path) }
          className={ `componentHook ${valid ? 'no-valid' : ''}` }
          inputProps={ { disabled: isNoEdit } }
        />
        { messageHtml }
      </div>
    );
  }

  types = {
    input: (label, field, value, locale) => this.renderInput(label, field, value, locale),
    checkbox: (label, field, value) => this.rendenCheckbox(label, field, value),
    img: (label, field, value) => this.renderImg(label, field, value),
    select: (label, field, value) => this.renderSelect(label, field, value),
    clock: (label, field, value) => this.renderClock(label, field, value),
    textArea: (label, field, value, locale) => this.renderTextArea(label, field, value, locale),
    autocomplete: (label, field, value) => this.renderAutocomolete(label, field, value),
  };

  renderElement = ({ label, field, value, type, locale }) => {
    const itemClasses = cn({
      [styles.item]: true,
      [styles.item_thumbnail]: field === FIELDS.THUMBNAIL,
      [styles.item_short_description]: field === FIELDS.SHORT_DESCRIPTION || field === FIELDS.SHORT_DESCRIPTION_EN,
      [styles.smart_hotel]: field === FIELDS.SMART_HOTEL,
    });

    return (
      <li key={ field } className={ itemClasses }>
        {this.types[type](label, field, value, locale)}
      </li>
    );
  }

  renderUnChangeFields = () => {
    const { unChangeFields } = this.props;
    const list = unChangeFields.reduce((acc, { value, label }, i) =>
      (value ? [...acc, (<div key={ `${label}=${i}` } className={ styles.simple_sub_head_item }><label>{label}:</label> {value}</div>)] : acc), []);
    return list.length ? (<div className={ styles.simple_sub_head }>{list}</div>) : null;
  }

  render() {
    const { simpleFields, title, setRef } = this.props;
    const keys = Object.keys(simpleFields);

    return (
      <div ref={ ref => setRef(ref) }>
        <h2>{title}</h2>
        {this.renderUnChangeFields()}
        <ul className={ styles.list }>
          {keys.map(key => this.renderElement(simpleFields[key]))}
        </ul>
      </div>
    );
  }
}

export default SimpleBlock;
